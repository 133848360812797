import { useEffect } from "react"
import { navigate } from "gatsby"

/**
 * For now the 404 page just redirects to index
 */
const NotFoundHandler = () => {
  useEffect(() => {
    navigate("/")
  }, [])

  return null
}

export default NotFoundHandler
